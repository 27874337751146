html, body{
	font-family: arial;
	cursor: default;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin: 0;
}

#root {
	background-image: url("../images/background.png");
	background-size: cover;
	background-color: #5c616b;
}

#container{
	display: flex;
	background-color: transparent;
	/* background-image: url("../images/background.png"); */
	min-height: 100vh;
	color: #fff;
	align-items: center;
	/* background-size: cover; */
	grid-column-gap: 80px;
	column-gap: 80px;
	row-gap: 20px;
	flex-wrap: nowrap;
	flex-direction: column;
	overflow: auto;
}
.contentWrap {
	height: 100%;
	min-height: 100vh;
	/* background-image: url("../images/background.png");
	background-size: cover; */
	color: #fff;
	padding: 32px;
}
.ant-drawer-content {
	background-color: #202020d9!important;
}
.ant-drawer-wrapper-body {
	/* background-image: url("../images/background.png"); */
	background-size: cover;
	color: #fff;
}
.ant-drawer-title {
	color: #fff!important;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700!important;
}
.ant-table, .ant-table-cell, .ant-pagination-item-active {
	background: transparent!important;
	color: #fff!important;
}

/*notification*/
#notification{
	position: absolute;
	width: 500px;
	height: 318px;
	background-color: #ad0205;
	z-index: 10;
	border: 8px solid #d3b201;
	border-radius: 12px;
	transition: 1s;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 16px;
	padding: 10px;
	line-height: initial;
}
.resultBlock {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 8px;
}

.containerBody {
	display: flex;
	justify-content: center;
	column-gap: 80px;
	flex-wrap: wrap;
	flex: 1 0 auto;
}
.headerWrap {
	display: flex;
	padding: 1rem 1rem 0 1rem;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.nSpan{
	display: block;
	position: relative;
	font-size: 53px;
	text-align: center;
}

.wheelWrap {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	align-items: center;
}
.wheelWrap .countdown {
	display: flex;
	align-items: center;
	justify-content: center;
}
.pageTitle {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 32px;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 1rem;
	padding: 0 16px;
}

.nBtn{
	display: block;
	position: relative;
	width: 45%;
	padding: 10px;
	font-size: 32px;
	text-align: center;
	background-color: green;
	border-radius: 15px;
	box-shadow: 3px 4px rgb(0 0 0 / 25%);
	cursor: pointer;
	transition: .5s;
	cursor: pointer;
}

.nBtn:active{
	box-shadow: 1px 2px rgb(0 0 0 / 25%);
}

.nsWin{
	position:absolute;
	left: 4px;
}

.nsWinBlock{
	font-size: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
}
/*notification end*/

/*betting table*/
#betting_board{
	width: 500px;
	min-width: 500px;
	height: fit-content;
	border: 1px solid;
	position: relative;
	margin-right: 70px;
	margin-bottom: 70px;
}

.bbtop{
	width: 100%;
}

.bbtoptwo{
	width: 50%;
	border: 1px solid;
	display: inline-block;
	padding: 12px 4px;
	text-align: center;
	font-size:20px;
	font-weight: bold;
	cursor: pointer;
}

.number_board{
	display: grid;
	width: calc(100% + 65px);
	height: 100%;
	grid-template-columns: repeat(13, 1fr);
}

.number_block{
	border: 1px solid;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.nbn{
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 2px;
	transform: rotate(-90deg);
	letter-spacing: 2px;
	white-space: nowrap;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.oto_board{
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}

.bo3_board {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.bo3_block, .oto_block {
	border: 1px solid;
	display: inline-block; 
	padding: 8px 5px;
	text-align: center;
	font-weight: bold;
	font-size: 20px;
	cursor: pointer;
}

.number_0{
	position: absolute;
	border: 2px solid;
	margin-left: -57px;
	margin-top: -0.516px;
	padding: 120.55px 20px;
	border-bottom-left-radius: 100%;
	border-top-left-radius: 100%;
	height: 270px;
	cursor: pointer;
}

.tt1_block{
	border: 2px solid;
	width: 65px;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.winning_lines{
	position: absolute;
	width: 500px;
	height: 0;
}

.wlttb{
	position: absolute;
	width: 100%;
	height: 10px;
	left: 11px;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
}

#wlttb_top{
	top: 51px;
	left: 32px;
}
#wlttb_1 {
	top: 140px;
}
#wlttb_2 {
	top: 231px;
}
#wlttb_3 {
	top: 321px;
}

.wlrtl{
	position: absolute;
	display: inline-block;
	width: 10px;
	height: 266px;
	top: 58px;
	margin-left: 37px;
}

#wlrtl_2 {
	margin-left: 78px;
}
#wlrtl_3 {
	margin-left: 120px;
}
#wlrtl_4 {
	margin-left: 161px;
}
#wlrtl_5 {
	margin-left: 203px;
}
#wlrtl_6 {
	margin-left: 244px;
}
#wlrtl_7 {
	margin-left: 286px;
}
#wlrtl_8 {
	margin-left: 327px;
}
#wlrtl_9 {
	margin-left: 369px;
}
#wlrtl_10 {
	margin-left: 410px;
}
#wlrtl_11 {
	margin-left: 452px;
}

.wlcb{
	position: absolute;
	width: 456px;
	height: 28px;
	top: 131px;
	left: 32px;
	display: grid;
	grid-template-columns: repeat(11, 1fr);
	align-items: center;
}

#wlcb_2{
	top: 222px;
}

.cbbb{
	width: 20px;
	height: 20px;
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.ttbbetblock{
	width: 20px;
	height: 10px;
	position: relative;
	cursor:pointer;
	z-index:3;
}

.rtlbb1, .rtlbb2, .rtlbb3{
	height: 45px;
	margin-top: 23px;
	width: 10px;
}

.rtlbb2, .rtlbb3{
    margin-top: 42px;
}

.spinBtn{
	width: 38px;
	height: 38px;
	font-size: 16px;
	cursor: pointer;
	background-color: #ffec00;
	padding: 10px 5px;
	border-radius: 100%;
	color: #000;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
}

.redNum{
	background-color: #E0080B;
}

.blackNum{
	background-color: #000;
}

.chipDeck, .bankContainer{
	width: 360px;
	height: 48px;
	padding: 4px;
	border: 1px solid;
	border-radius: 10px;
	box-shadow: inset -2px -4px rgb(0 0 0 / 20%);
	display: flex;
	column-gap: 4px;
}

.cdChip{
	width: 38px;
	height: 38px;
	background-color: #fff;
	border: 5px solid;
	border-radius: 100%;
	display: inline-block;
	margin-left: 4px;
	color: #000;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cdChipSpan{
	position: relative;
	font-weight: bold;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cdChipActive{
	position: relative;
	top: -4px;
	box-shadow: 0 4px rgb(0 0 0 / 20%);
}

.clearBet{
	border-color:red;
	background-color:red;
	color:#fff;
	margin-left:18px;
}

.clearBet .cdChipSpan{
	margin-left:-2px;
}

.betAll{
	border-color:gold;
	background-color:gold;
	color:#fff;
}

.bank, .bet{
	display: inline-block;
	width: calc(50% - 28px);
	background-color: #000;
	font-size: 18px;
	padding: 6px;
	border: 4px solid silver;
	border-radius: 7px;
	text-align: right;
	flex: 1 auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.pnBlock{
	position: absolute;
	border: 2px solid;
	width: 500px;
	height: 46px;
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#pnContent{
	border: 4px solid #d5b714;
	width: 486px;
	height: 32px;
	position: relative;
	display: block;
	background-color: #fff;
	color: #000;
	overflow:hidden;
	display: flex;
	align-items: center;
	font-size: 18px;
}

.pnRed, .pnBlack, .pnGreen{
	position: relative;
	display: inline;
	padding: 0 10px;
}.pnRed{
	color: red;
}.pnBlack{
	color: black;
}.pnGreen{
	color: green;
}
/*betting table end*/

/*wheel*/
@keyframes wheelRotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
@keyframes ballRotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-359deg);
	}
}

.wheel {
	height: 312px;
	width: 312px;
	position: relative;
}
	
.ballTrack{
	width: 212px;
	height: 207px;
	position: absolute;
	left: 47.5px;
	top: 50px;
	border-radius: 100%;
	z-index:2;
}

.ball{
	background-color: #fff;
	width: 16px;
	height: 16px;
	border-radius: 100%;
	position: relative;
	left: 109px;
	top: -8px;
}
	
.turret{
	border-radius: 100%;
	background: radial-gradient( circle at 30% 30%, #f3c620, #1a1608 );
	width: 45px;
	height: 45px;
	position: absolute;
	top: 133px;
	left: 134px;
	z-index: 1;
}
	
.turretHandle{
	background: radial-gradient( circle at 44%, #f3c620, #6a570f);
	width: 87px;
	height: 14px;
	position: absolute;
	left: 113px;
	top: 148px;
}

.thendOne, .thendTwo{
	border-radius: 100%;
	background: radial-gradient( circle at 30% 30%, #f3c620, #1a1608 );
	width: 25px;
	height: 25px;
	position: absolute;
}

.thendOne{
	top: -6px;
	left: -20px;
}

.thendTwo{
	top: -6px;
	left: 83px;
}

.outerRim {
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
	border: 10px solid #F3c620;
} 

.block {
	transition: all 1s;
	position: absolute;
	width: 290px;
	height: 290px;
	border-radius: 100%;
	clip: rect(0px, 145px, 290px, 0px);
}

.sect {
	position: absolute;
	top:10px;
	left:10px;
	width: 290px;
	height: 290px;
	border-radius: 100%;
	clip: rect(0px, 290px, 290px, 145px);
}

.double, .single{
	position: absolute;
	z-index: 1;
	color: #fff;
	font-size: 14px;
	transform: rotate(3deg);
}

.double{
	left: 148px;
	margin-top: 4.5px;
}

.single{
	left: 152px;
	margin-top: 4.5px;
}

#sect1 .block {
	background-color: #016D29;
	transform:rotate(9.73deg);
}
    
#sect2 .block, #sect4 .block, #sect6 .block, #sect8 .block, #sect10 .block, #sect12 .block, #sect14 .block, #sect16 .block, #sect18 .block, #sect20 .block, #sect22 .block, #sect24 .block, #sect26 .block, #sect28 .block, #sect30 .block, #sect32 .block, #sect34 .block, #sect36 .block {
	background-color: #E0080B;
	transform: rotate(9.73deg);
}

#sect3 .block, #sect5 .block, #sect7 .block, #sect9 .block, #sect11 .block, #sect13 .block, #sect15 .block, #sect17 .block, #sect19 .block, #sect21 .block, #sect23 .block, #sect25 .block, #sect27 .block, #sect29 .block, #sect31 .block, #sect33 .block, #sect35 .block, #sect37 .block {
	background-color: #000;
	transform: rotate(9.73deg);
}

#sect2 {
	transform: rotate(9.73deg);
}

#sect3 {
	transform: rotate(19.46deg);
}

#sect4 {
	transform: rotate(29.19deg);
}

#sect5 {
	transform: rotate(38.92deg);
}

#sect6 {
	transform: rotate(48.65deg);
}

#sect7 {
	transform: rotate(58.38deg);
}

#sect8 {
	transform: rotate(68.11deg);
}

#sect9 {
	transform: rotate(77.84deg);
}

#sect10 {
	transform: rotate(87.57deg);
}

#sect11 {
	transform: rotate(97.3deg);
}

#sect12 {
	transform: rotate(107.03deg);
}

#sect13 {
	transform: rotate(116.76deg);
}

#sect14 {
	transform: rotate(126.49deg);
}

#sect15 {
	transform: rotate(136.22deg);
}

#sect16 {
	transform: rotate(145.95deg);
}

#sect17 {
	transform: rotate(155.68deg);
}

#sect18 {
	transform: rotate(165.41deg);
}

#sect19 {
	transform: rotate(175.14deg);
}

#sect20 {
	transform: rotate(184.87deg);
}

#sect21 {
	transform: rotate(194.6deg);
}

#sect22 {
	transform: rotate(204.33deg);
}

#sect23 {
	transform: rotate(214.06deg);
}

#sect24 {
	transform: rotate(223.79deg);
}

#sect25 {
	transform: rotate(233.52deg);
}

#sect26 {
	transform: rotate(243.25deg);
}

#sect27 {
	transform: rotate(252.98deg);
}

#sect28 {
	transform: rotate(262.71deg);
}

#sect29 {
	transform: rotate(272.44deg);
}

#sect30 {
	transform: rotate(282.17deg);
}

#sect31 {
	transform: rotate(291.9deg);
}

#sect32 {
	transform: rotate(301.63deg);
}

#sect33 {
	transform: rotate(311.36deg);
}

#sect34 {
	transform: rotate(321.09deg);
}

#sect35 {
	transform: rotate(330.82deg);
}

#sect36 {
	transform: rotate(340.55deg);
}

#sect37 {
	transform: rotate(350.28deg);
}

.pockets {
	position: absolute;
	top: 37px;
	left: 37px;
	width: 235px;
	height: 235px;
	background-color: #000;
	border-radius: 100%;
	opacity: .5;
}

.pocketsRim {
	position: absolute;
	top: 34px;
	left: 34px;
	width: 235px;
	height: 235px;
	border-radius: 100%;
	border: 3px solid silver;
}
	
.cone {
	position: absolute;
	top: 62px;
	left: 61px;
	height: 180px;
	width: 180px;
	border: 3px solid #9f9a9a;
	border-radius: 100%;
	background: radial-gradient(circle at 100px 100px, #892c10, #000);
}
/*wheel end*/

/*chip*/
.chip{
	width: 21px;
	height: 21px;
	background-color:#fff;
	border: 3px solid;
	border-radius: 100%;
	position:absolute;
}

.chipSpan{
	color: #000;
	font-weight: bold;
	font-size: 11px;
	position: relative;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.chipGuess{
	width: 21px;
	height: 21px;
	background-color:darkslategray;
	border: 3px solid;
	border-radius: 100%;
	position:absolute;
	/* pointer-events: none; */
}
.chipSpanGuess {
	color: #fff;
	font-weight: bold;
	font-size: 11px;
	position: relative;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.gold{
	border-color:gold;
}

.red{
	border-color:red;
}

.orange{
	border-color:orange;
}

.blue{
	border-color:blue;
}

.wlrtl .chip{
	margin-left: -5px;
	margin-top: 9px;
}
.ttbbetblock .chip{
	margin-top: -5px;
}

#wlttb_top .chip{
	margin-left: -2px;
	margin-top: -5px;
}

.bbtoptwo .chip{
	margin-left: 108px;
	margin-top: -25px;
}

.number_0 .chip{
	margin-left: -8px;
	margin-top: -22px;
}

.bo3_block .chip{
	margin-left: 65px;
	margin-top: -26px;
}

.oto_block .chip{
	margin-left: 45px;
	margin-top: -25px;
}
/*chip end*/

.wrapBtn {
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
}
.ant-modal-content {
	background-color: #000!important;
	background-image: url("../images/background.png");
	background-size: cover;
}
.nftBoxGrid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem;
  width: 100%;
	height: 100%;
  display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	grid-auto-rows: 260px;
	grid-gap: 8px;
  justify-content: center;
	overflow: auto;
	padding-bottom: 80px;	
	background: #000;
}
.nftBoxGridTooltip {
	max-width: 400px;
	grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
	grid-auto-rows: 40px;
	grid-gap: 4px;
	padding: 0.2rem;
}
.nftBoxPlayerGrid {
	/* max-width: 400px; */
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-auto-rows: 100px;
	grid-gap: 8px;
	padding: 1rem;
	border: none;
	background-color: transparent;
}
.nftBox {
  border-radius: 16px;
  border: 1px solid #ccc;
  overflow: hidden;
  cursor: pointer;
	width: fit-content;
}
.nftBox h4 {
  margin: 1rem 0.5rem;
  text-align: center;
	color: #fff;
}
.nftBox .ant-image {
	height: 100%;
	width: 100%;
	overflow: hidden;
	width: fit-content;
}
.nftBox .ant-image .ant-image-img {
	height: 100%;
	width: auto;
	object-fit: contain;
}
.nftBoxPlayerInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
.nftAvatar {
	width: 100%;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 25px;
	cursor: pointer;
	position: relative;
}
.nftAvatar .ant-image {
	height: 100%;
}
.nftAvatar .ant-image .ant-image-img {
	height: 100%;
	width: auto;
}
.nftAvatarWrap {
	position: relative;
	height: 100%;
}
.nftAvatarWrap .nftAvatarCount {
	position: absolute;
	top: 0;
	right: 0;
	padding: 2px;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	border: 1px solid #1998db;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	color: #5c616b;
	font-weight: 700;
}
.selectedNFTStake {
  border: 2px solid #1998db;
  box-shadow: 0px 10px 36px -1px lightblue;
}
.selectedNFTWithdraw {
  border: 2px solid red;
  box-shadow: 0px 10px 36px -1px red;
}
.footerControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 8px 32px;
  gap: 8px;
	position: absolute;
	bottom: 0;
	left: 0;
	background: #000000b8;
}
.formControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 8px 32px;
  gap: 8px;
	position: absolute;
	bottom: 0;
	background: #fff;
}
.formContent {
  display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  gap: 8px;
}
.wheelContainer {
	display: flex;
	gap: 16px;
}
.dealerWrap .dealerImage {
	height: 200px;	
	width: auto;
	object-fit: contain;
	transform: rotateY(180deg);
}
.dealerWrap .ant-image {
	height: 312px;
	display: flex;
	align-items: center;
}

.leaderBoard {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	padding: 8px;
	font-size: 18px;
	height: fit-content;
	cursor: pointer;
}
.lbTitle {
	writing-mode: vertical-rl;
  text-orientation: upright;
}

.ant-table-placeholder, .ant-table-cell {
	background: transparent!important;
}
.ant-empty-description {
	color: #fff!important;
}
.iconWrap {
	font-size: 24px;
	cursor: pointer;
	background: #ffffff47;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
}
.settingBox {
	background: #000000ba;
	border: none;
	border-radius: 0.5rem;
	box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}

.tableGrid {
  margin: 0 auto;
  width: 100%;
	height: 100vh;
  display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-gap: 16px;
  justify-content: center;
	overflow: auto;
	padding: 80px;	
}
.tableItem {
	position: relative;
	padding: 10px;
	padding-bottom: 20px;
	border: 1px solid #fff;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
.tableItem:hover {
	border: 4px solid;
	border-radius: 8px;
  border-image: linear-gradient(45deg,rgb(242, 242, 242),rgb(242, 255, 0)) 10;
}
.tableImage {
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;
	position: relative;
}
.tableImage image {
	height: auto;
	width: 100%;
	object-fit: contain;
	position: absolute;
	width: 80%;
	left: 0;
}
.tableImage .ant-image {
	position: initial;
}
.rouletteImage {
	position: absolute;
	bottom: 0;
	width: 80%!important;
}
.tableInfo {
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.loadingBg {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}
.loadingBg svg {
	opacity: 0.7;
}

@media only screen and (max-width: 768px){
  .pageTitle{
    font-size: 24px;
  }
	.containerBody {
		transform: scale(0.8);
	}
	.leaderBoard {
		flex-direction: row;
	}
	.lbTitle {
		writing-mode: initial;
	}
	#betting_board {
		transform: scale(0.66);
		margin-left: 70px;
	}
}
